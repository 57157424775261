import React, { useState, useEffect, useCallback, useRef } from 'react';

function getHost() {
  let host = window.location.origin;
  host += '/api';

  return host;
}

function ProjectsEntry( params ) {
  let project = params.project;
  let id = params.id;
  let [collapse, setCollapse] = useState( project.new != true );

  let [titleEdit, setTitleEdit] = useState( false );
  let [descEdit, setDescEdit] = useState( false );

  let titleRef = useRef( null );
  let descRef = useRef( null );
  let imgRef = useRef( null );
  let imgOverlayRef = useRef( null );
  let fileRef = useRef( null );

  let [descxy, setDescXY] = useState( null );
  let [needsFocus, setNeedsFocus] = useState( null );

  let [titleInput, setTitleInput] = useState( project.title );
  let [descInput, setDescInput] = useState( project.description );
  let [imgInput, setImgInput] = useState( project.image );

  let onTitleFocus = () => { setTitleEdit( true ); setNeedsFocus( titleRef ); };
  let onDescFocus = ( e ) => {
    setDescEdit( true );
    setNeedsFocus( descRef );

    let rect = e.target.getBoundingClientRect();
    setDescXY( {
      w: parseInt( rect.right - rect.left ),
      h: parseInt( rect.bottom - rect.top )
    } );
  };

  let onImgHoverEnter = ( e ) => {
    if( e.target != imgRef.current )
      return;

    imgOverlayRef.current.style.opacity = 1;
    e.target.style.opacity = 0.7;
    e.target.style.border = "1px solid #fff";
  };

  let onImgHoverExit = ( e ) => {
    if( e.target != imgRef.current )
      return;

    imgOverlayRef.current.style.opacity = 0;
    e.target.style.opacity = 1.0;
    e.target.style.border = '1px solid rgba(0,0,0,0)';
  }

  let onImgPicked = ( e ) => {
    setImgInput( e.target.files[0] );

    const reader = new FileReader();
    reader.onload = ( e ) => {
      imgRef.current.src = e.target.result;
    }

    reader.readAsDataURL( e.target.files[0] );
  }

  let discardAll = () => {
    setTitleInput( project.title );
    setDescInput( project.description );
    setImgInput( project.image );
  }

  let deleteProject = async () => {
    if( !window.confirm( `are you sure you want to delete project id ${id}:\ntitle: ${project.title}\ndescription: ${project.description}\nimage: ${project.image}` ) )
      return;

    try {
      let res = await fetch( `${getHost()}/delete-project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( {
          token: localStorage.getItem( 'session' ),
          id
        } )
      });

      if( !res.ok ) {
        throw new Error( res.statusText );
      }

      let json = await res.json();
      if( json.status == 'ok' ) {
        window.location.reload();
      }
    } catch( e ) {
      alert( `error deleting entry: ${e.message}` );
    }
  }

  let submitChanges = async () => {
    let formData = new FormData();
    formData.append( 'title', titleInput );
    formData.append( 'description', descInput );
    formData.append( 'img', imgInput );
    formData.append( 'id', id );
    formData.append( 'token', localStorage.getItem( 'session' ) );
    try {
      let res = await fetch( `${getHost()}/update-entry`, {
        method: 'POST',
        body: formData
      } );

      if( !res.ok )
        throw new Error( `error updating data: ${res.statusText}` );

      let json = await res.json();
      if( json.status == 'ok' ) {
        setCollapse( true );
        let data = JSON.parse( json.data );
        setImgInput( data.image );
      } else
        alert( `error saving data: ${json.msg}` );
    } catch( e ) {
      alert( `error saving data: ${e}` );
    }
  }

  useEffect( () => {
    if( needsFocus && needsFocus.current ) {
      needsFocus.current.focus();
      setNeedsFocus( null );
    }
  }, [needsFocus] );

  if( collapse ) {
    return <>
      <div className="left" style={{width: "100%", height: 80, backgroundColor: 'rgba(130, 140, 220, 0.2)', marginBottom: 15, borderRadius: 10, display: 'flex', opacity: 0}}>
        <div style={{width: 185, display: 'flex', justifyContent: 'center', paddingRight: 10}}>
          <img src={imgInput} alt={titleInput} style={{height: "70px", maxWidth: 170, paddingTop: 5, paddingLeft: 5, borderRadius: 5}}/>
        </div>
        <div style={{width:"100%"}}>
          <h2>{titleInput}</h2>
        </div>
        <button style={{height: "50%", marginTop: 17.5, borderRadius: 5, width:150, fontFamily: 'inherit'}} onClick={()=>setCollapse(false)}>EXPAND/EDIT</button>
      </div>
    </>
  }

  return <>
    <div
      key={id}
      className={`project-fullpage active`}
      style={{height: 'auto'}}
    >
      <div className="project-content">
        <div className="project-left left">
          {
            ( () => {
              if( titleEdit === true ) {
                return <input type="text" className='title-input'
                  value={titleInput}
                  ref={titleRef}
                  onChange={e => { setTitleInput(e.target.value); }}
                  onBlur={() => setTitleEdit( false )}
                />
              }

              return <h2 className="editable hover-pointer" onClick={onTitleFocus}>{titleInput}</h2>
            } )()
          }
          <img src={imgInput}
            ref={imgRef}
            className='hover-pointer'
            alt={project.title}
            onMouseEnter={onImgHoverEnter}
            onMouseLeave={onImgHoverExit}
            onClick={() => fileRef.current.click()}
            style={{border: "1px solid rgba( 0,0,0,0 )", minHeight: 150}}
          />
          <input
            ref={fileRef}
            type="file"
            onChange={onImgPicked}
            style={{display: "none", pointerEvents: 'none'}}
          />
          <div style={{position: "absolute", left: 200, top: 150, opacity: 0, pointerEvents: 'none'}} ref={imgOverlayRef}>
            <h1 style={{color: "#ccc", fontWeight: 300}}>edit ...</h1>
          </div>
        </div>
        <div className="project-right right">
          <div style={{position: 'absolute', right: 0, top: 20, width: 360}}>
            <button onClick={() => discardAll()} style={{borderRadius: 5, backgroundColor: "#bbb", marginRight: 5, fontFamily: 'inherit'}}>DISCARD CHANGES</button>
            <button onClick={() => submitChanges()} style={{borderRadius: 5, fontFamily: "inherit"}}>SAVE AND COLLAPSE</button>
            <button onClick={() => deleteProject()} style={{borderRadius: 5, backgroundColor: "#f00", marginLeft: 5, height: 40}}>🗑</button>
          </div>
          { (() => {
            if( descEdit === true ) {
              return <textarea className='desc-input'
                value={descInput}
                ref={descRef}
                onChange={e => { setDescInput(e.target.value); }}
                onBlur={() => setDescEdit( false )}
                style={{width: descxy.w, height: descxy.h}}
              />
            }

            return <p className="editable hover-pointer" onClick={onDescFocus}>{descInput}</p>
          })() }
          <a href={project.url}>{project.urltext}</a>
        </div>
      </div>
    </div>
    </>;
}


function Admin() {
  let [projects, setProjects] = useState( [] );
  let [loggedIn, setLoggedIn] = useState( false );
  let [password, setPassword] = useState( '' );
  let passRef = useRef( null );

  const getToken = useCallback( async () => {
    if( password.length < 2 )
      return;

    try {
      let res = await fetch( `${getHost()}/generate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify( { password } ),
      } );

      if( !res.ok )
        throw new Error( "http error " + res.status() );

      let json = await res.json();
      if( json.status == 'ok' ) {
        localStorage.setItem( 'session', json.token );
        setLoggedIn( true );
      }
    }
    catch( e ) {
    }
  }, [password] );

  const addNew = async () => {
    try {
      let res = await fetch( `${getHost()}/create-new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify( {
          token: localStorage.getItem( 'session' )
        } )
      });

      if( !res.ok )
        throw new Error( 'http request failed ' + res.statusText );

      const data = await res.json();
      if( data.status == 'ok' ) {
        let proj = projects.slice();
        proj.push( { title: 'untitled', image: '', description: 'no description' } );
        setProjects( proj );
      }
    } catch( e ) { alert( 'error adding project: ' + e.message ); }
  };

  useEffect(() => {
    async function getProjects() {
      try {
        const response = await fetch( `${getHost()}/projects` );
        if( !response.ok ) throw new Error(`HTTP error! status: ${response.status}`);
        const projectsData = await response.json();
        setProjects( projectsData );
      } catch( e ) { alert( `Error fetching projects: ${e.message}` ); }
    }

    async function authenticate() {
      let session = localStorage.getItem( 'session' );
      if( session && session.length > 0 ) {
        try {
          let res = await fetch( `${getHost()}/verify-token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify( {
              token: session
            } )
          } );

          let json = await res.json();
          if( json.status != 'ok' )
            throw new Error( 'invalid token' );

          setLoggedIn( true );
        } catch( e ) {
          setLoggedIn( false );
          localStorage.removeItem( 'session' );
        }
      }

      if( loggedIn )
        getProjects();
    }

    authenticate()
  }, [loggedIn]);

  return (
    <div className="admin">
      <h1>admin</h1>
      {
        loggedIn ? projects.map( ( proj, id ) => { return <ProjectsEntry key={ id } project={ proj } id={ id } /> } ) :
          <div>
            <h3>log in</h3>
            <input type="password" ref={passRef} value={password} onChange={ ( e ) => { setPassword( e.target.value ) } } style={{width: 200}}/>
            <button onClick={getToken} style={{borderRadius: 5, marginLeft: 7, height: 33}}>Sign in</button>
          </div>
      }
      <div style={{width: "100%", height: 100, textAlign: 'right'}}>
        <button style={{borderRadius: 5}} onClick={addNew}>add new</button>
      </div>
    </div>
  );
}

export default Admin;
